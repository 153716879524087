var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.dataLoaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" WireGuard Interface Status: "+_vm._s(_vm.interface.name)+" ")]),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Public Key: "+_vm._s(_vm.interface.publicKey))]),_c('v-list-item-subtitle',[_vm._v("Listening Port: "+_vm._s(_vm.interface.listenPort))]),_c('v-list-item-subtitle',[_vm._v("Device Type: "+_vm._s(_vm.interface.type))]),_c('v-list-item-subtitle',[_vm._v("Number of Peers: "+_vm._s(_vm.interface.numPeers))])],1)],1)],1)],1)],1):_vm._e(),(_vm.dataLoaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" WireGuard Client Status "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.reload}},[_vm._v(" Reload "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-reload")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clients,"search":_vm.search},scopedSlots:_vm._u([{key:"item.connected",fn:function(ref){
var item = ref.item;
return [(item.connected)?_c('v-icon',{attrs:{"left":"","color":"success"}},[_vm._v("mdi-lan-connect")]):_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-lan-disconnect")])]}},{key:"item.receivedBytes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.humanFileSize(item.receivedBytes))+" ")]}},{key:"item.transmittedBytes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.humanFileSize(item.transmittedBytes))+" ")]}},{key:"item.allowedIPs",fn:function(ref){
var item = ref.item;
return _vm._l((item.allowedIPs),function(ip,i){return _c('v-chip',{key:i,attrs:{"color":"indigo","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-ip-network")]),_vm._v(" "+_vm._s(ip)+" ")],1)})}},{key:"item.lastHandshake",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(item.lastHandshake))+" ("+_vm._s(item.lastHandshakeRelative)+")")])])]}}],null,false,1537719046)})],1)],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" No stats available... ")]),(_vm.enabled)?_c('v-card-text',[_vm._v(_vm._s(_vm.error))]):_c('v-card-text',[_vm._v("Status API integration not configured.")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }